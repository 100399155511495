import { useEffect, useState } from "react";
import { ApiUrl } from "./components/API/Api";
import Quotation from "./components/Quotation";
import Youtube from "./components/Youtube";
import About from "./components/about";
import Footer from "./components/footer";
import Header from "./components/header";
import Ourstatic from "./components/ourstatic";
import Scrollbar from "./components/scrollbar";
import Slider from "./components/slider";
import Upcoming from "./components/upcoming";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";

function Home() {
  const [homedata, setHomedata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <InfinitySpin
            visible={true}
            height="100"
            width="100"
            color="#001c38"
            ariaLabel="salem-diocese-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />

        </div>
      )}

      {!isLoading && (
        <>
          <Header menudata={homedata?.headermenudata} />
          <Slider sliderdata={homedata?.SlidesData} />
          <Scrollbar />
          <About />
          <Ourstatic />
          <Upcoming
            projectdata={homedata?.upcomingevents}
            newsletterdata={homedata?.upcomingevents}
          />
          <Quotation />
          <Youtube />
          <br />
          <Footer />
        </>
      )}
    </>
  );
}
export default Home;
