import React, { useEffect, useState } from "react";
import axios from "axios";

import ImageGallery from "react-image-gallery";
import "../../components/gallerystyle.css";
import { ApiUrl } from "../../components/API/Api";

const Photos = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/gallery_images`)
      .then((response) => {
        setGalleryImages(response?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching gallery images:", error);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, []);

  const images =
    galleryImages?.map((image) => ({
      original: image?.image || "",
      thumbnail: image?.image || "",
    })) || [];

  // Additional check before using forEach
  if (galleryImages) {
    galleryImages.forEach((image) => {
      images.push({
        original: image.image || "",
        thumbnail: image.image || "",
      });
    });
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-12">
          <div className="section-heading">
            <h2 className="entry-title">Gallery Photos</h2>
          </div>
          {loading ? (
            <p>
              <b>Loading...</b>
            </p>
          ) : galleryImages.length === 0 ? (
            <p>
              <b>No gallery images available</b>
            </p>
          ) : (
            <ImageGallery items={images} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Photos;
