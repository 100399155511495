import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
function History() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [id, setPageTitle] = useState("");
  let location = useLocation();
  const url = location.pathname;
  console.log(url);
  useEffect(() => {
    const path = {
      "/cathedral": 1,
      "/history": 2,
      "/bishop-emeritus": 3,
      "/patron": 4,
    };
    setPageTitle(path[url] ? path[url] : url);
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <CircularProgress
        sx={{ display: "block", margin: "auto", marginTop: "5em" }}
      />
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="text-center mt-5">
        <b>No Data Available</b>
      </div>
    );
  }

  const filteredData = data.filter((item) => item.id === id);

  if (filteredData.length === 0) {
    return (
      <div className="text-center mt-5">
        <b>No matching data found</b>
      </div>
    );
  }

  return (
    <>
      <div className="container subpage">
        <div className="row">
          <div className="col-lg-12">
            {filteredData.map((item) => (
              <div key={item.id}>
                <h2 className="heading">{item.title}</h2>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: `<style>table { width: 100%; max-width: 100%; border-collapse: collapse; } table, th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } @media (max-width: 600px) { table, th, td { display: block; width: 100%; box-sizing: border-box; } } </style>${item.content}`,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-lg-6">
          <h1>History of the Diocese</h1>
          <br />
          <p>
            The Diocese of Salem comprises of civil districts of Salem and
            Namakkal. In 1623 Southern Kongunadu, part of Salem District, under
            Madurai Nayakkars accepted Christianity, through Fr. Robert De
            Nobili, S.J. and his successors, the Portuguese Jesuits of the
            Madurai Mission. About 1654, the Italian Jesuits of the Mysore
            mission had a residence near Hosur. In 1687, the Mysore Mission
            laboured in the entire area of the present diocese. Fr. De Cunha
            died in 1711 at Kapiganathi near Hosur. Even after the suppression
            of theirSociety, the Jesuits under the jurisdiction of Cranganore,
            worked to the last in the field. In 1785, during Tippu Sulttan’s
            rule, Msgr. Champenois, Superior of the Malabar Mission, was
            entrusted by Rome with the Mysore Mission, which took care of Salem.
            He was the first Bishop to visit Salem. Salem Mission was brought
            under the Brirtish rule, and he left in charge there “Fr. Abbe
            Dubois”, who strove to restore the devastated mission.
          </p>
        </div>
        <div className="col-lg-6">
          <img
            src="images/all-img/Salemmap.jpg"
            style={{ width: "100%" }}
            alt=""
          />
        </div>
        <p>
          From 1797, Kartenaras from Cranganore intruded into Salem till they
          left about 1833. The area continued to depend on the Coromandal
          mission. Later it came under the Vicariate Apostolic of Pondicherry,
          which in 1886 became an Archdiocese. This set up continued till 1930
          except for Hosur Taluk which had been entrusted from 1861 to the area
          of Mysore and for the southeast which was joined in 1899 to the
          Diocese of Kumbakonam. On May 26, 1930 the Diocese of Salem was
          erected from the parts of Salem District and from parts under the
          jurisdiction of Pondicherry Diocese(6 parishes), Kumbakonam Diocese (3
          parishes), Mysore Diocese (1parish) and in 1943 the Western portion of
          Mettur Taluk (2 parishes) was added. In 1949, the Diocese of Salem was
          entrusted to an Indian, its second Bishop Rt. Rev. V.S. Selvanather.
          Diocese of Dharmapuri was bifurcated from the Diocese of Salem
          according to the communique released on February 28,1997. The Holy
          Father appointed Msgr. Joseph Antony Irudaya Raj, SDB as the first
          Bishop of Dharmapuri.
        </p>
        <br />
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img
                  src="images/all-img/Bishop-Prunier-64.jpg"
                  className="img-responsive"
                  alt=""
                />
                <div className="social">
                  
                </div>
              </div>
              <div className="img-text">
                <h4>Bishop H.A. Prunier</h4>
                <h5>(1930-1949)</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img
                  src="images/all-img/Venmani_S._Selvanather.jpg"
                  className="img-responsive"
                  alt=""
                />
                <div className="social">
                 
                </div>
              </div>
              <div className="img-text">
                <h4>Bishop V.S. Selvanather</h4>
                <h5>(1949-1973)</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img
                  src="images/all-img/Bishop-Michael-B-Duraisamy-1.jpg"
                  className="img-responsive"
                  alt=""
                />
                <div className="social">
                  
                </div>
              </div>
              <div className="img-text">
                <h4>Bishop Michael Bosco Duraisamy</h4>
                <h5>(1974-1999)</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img
                  src="images/all-img/Bp.-Sebastianappan-Singaroyan-Bishop-of-Salem.png"
                  className="img-responsive"
                  alt=""
                />
                <div className="social">
                  
                </div>
              </div>
              <div className="img-text">
                <h4>Bishop Sebastianappan Singaroyan</h4>
                <h5>(July 5, 2000 – March 9, 2020)</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img
                  src="images/all-img/1421994018-1.jpg"
                  className="img-responsive"
                  alt=""
                />
                <div className="social">
                 
                </div>
              </div>
              <div className="img-text">
                <h4>Bishop Lawrence Pius Dorairaj</h4>
                <h5>
                  Apostolic Administrator (March 9, 2020 – August 4, 2021)
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default History;
