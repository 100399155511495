import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function UniqueStructure() {
  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-lg-8">
          <div className="row mb-2">
            <div className="col">
              {/* Content-1 */}
              <Link
                to={"/bishopprofile"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/bishop.jpeg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        Bishop’s Profile
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>

            <div className="col">
              {/* Content-2 */}
              <Link
                to={"/bishopmessage"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/message.jpg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        Bishop’s Message
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
            <div className="col">
              {/* Content-1 */}
              <Link
                to={"/monthlyprogram"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/program.jpg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        Monthly Program
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              {/* Content-1 */}
              <Link
                to={"/newsletter"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/newsletter.avif"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        News Letters
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
            <div className="col">
              {/* Content-2 */}
              <Link
                to={"/masstimings"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/masstime.jpg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        Mass Timings
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>

            <div className="col">
              {/* Content-1 */}
              <Link
                to={"/holyfathermessage"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/newmessage.png"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                          fontSize: "22px",
                          marginTop: "10px",
                        }}
                      >
                        Holy Father’s Message
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <Link
            to={"/bishopprofile"}
            className="responsive-link"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {/* Content-5 */}
            <div className="image-container">
              <Card style={{ backgroundColor: "#f5efe0", border: "none" }}>
                <Card.Img
                  variant="top"
                  src="/images/all-img/bishop.jpeg"
                  style={{ width: "70%" }}
                />
              </Card>
              <div className="bishop-info">
                <p>
                  <br />
                  <b>His Excellency</b>
                  <br />
                  <b>Most Rev. Dr. Arulselvam Rayappan</b>
                  <br />
                  <b>Born:</b> Nov-18, 1960
                  <br />
                  <b>Feast:</b> June-29
                  <br />
                  <b>Priestly Ordination:</b> May-20, 1986
                  <br />
                  <b>Appointed as Bishop:</b> May-31, 2021
                  <br />
                  <b>Episcopal Consecration :</b> Aug-04,2021
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Container>
  );
}

export default UniqueStructure;
