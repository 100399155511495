import React from "react";
import styled from "styled-components";
import { FiCalendar, FiMapPin, FiBookOpen } from "react-icons/fi";

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BishopImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
`;

const HeaderTitle = styled.h1`
  font-size: 24px;
  color: #333;
  margin: 0;
`;

const SectionContainer = styled.div`
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Detail = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: 10px;
`;

const IconWrapper = styled.span`
  margin-right: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const TableData = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const BishopProfile = () => {
  return (
    <ProfileContainer>
      <ProfileHeader>
        <BishopImage src="images/all-img/bishop.jpeg" alt="Bishop" />
        <HeaderTitle>
          Most Rev.Dr Arulselvam Rayappan <br />
          <p style={{ fontSize: "15px" }}> Bishop of Salem</p>
        </HeaderTitle>
      </ProfileHeader>
      <SectionContainer>
        <Section>
          <SectionTitle>
            <IconWrapper>
              <FiCalendar />
            </IconWrapper>
            Personal Information
          </SectionTitle>
          <Detail>
            <Label>Parent’s Name:</Label>
            <Value> T. Arulselvam & S. Kanikaimary</Value>
          </Detail>
          <Detail>
            <Label>Born:</Label>
            <Value> 18.11.1960</Value>
          </Detail>
          <Detail>
            <Label>Place of Birth:</Label>
            <Value>Sathipattu, Archdiocese of Pondicherry-Cuddalore</Value>
          </Detail>
          <Detail>
            <Label>Diocese of Origin:</Label>
            <Value>Archdiocese of Pondicherry-Cuddalore</Value>
          </Detail>
          <Detail>
            <Label>Priestly Ordination:</Label>
            <Value>
              May 20, 1986, for the Archdiocese of Pondicherry-Cuddalore
            </Value>
          </Detail>
          <Detail>
            <Label>Episcopal Consecration:</Label>
            <Value>August 4, 2021, Infant Jesus Cathedral, Salem</Value>
          </Detail>
          <Detail>
            <Label>More about the Bishop</Label>
            <Value>
              His Holiness Pope Francis has appointed Rev. Fr. Arulselvam
              Rayappan, of the Clergy of Pondicherry-Cuddalore, as Bishop of
              Salem. This provision was made public in Rome on Monday 31st May
              2021. The Episcopal Consecration took place on August 4, 2021 at
              Infant Jesus Cathedral, Salem.
            </Value>
            <br />
            <br />
            <Value>
              Bp. Arulselvam Rayappan completed his studies in the following
              Institutions: St. Agnes Minor Seminary and St. Joseph’s Hr. Sec.
              School, Cuddalore (1974-1978); Philosophy at Christ Hall Seminary,
              Madurai (1978-1981); Theology at St. Peter’s Pontifical Seminary,
              Bangalore (1982-1986). He was ordained priest on 20 May 1986 for
              the Archdiocese of Pondicherry-Cuddalore. He obtained a Masters in
              Canon Law from St. Peter’s Pontifical Institute, Bangalore
              (1990-1992) and Doctorate in Canon Law from Pontificia Università
              Urbaniana, Rome (1992-1994)
            </Value>
            <br />
            <br />
            <Value>
              He served in the following ministries: Vice-Rector, St. Agnes
              Minor Seminary, Cuddalore (1986-1988), Asst. Parish Priest at
              Viriyur and Karaikal (1988-1989), Parish Priest at Kurumbagaram
              (1989-1990). After his studies in Rome, since 1994, he was
              teaching Canon Law at St. Peter’s Pontifical Institute, Bangalore.
              He was then Director of the Centre for Canon Law Studies at St.
              Peter’s Pontifical Institute, Bangalore (1996-2001), Vice-Rector,
              St. Peter’s Pontifical Seminary, Bangalore (200-2006), President,
              St. Peter’s Pontifical Institute, Bangalore (2010-2017).
            </Value>
            <br />
            <br />
            <Value>
              He served as the Executive Secretary of CCBI Commission for Canon
              Law and Legislative Texts (2010-2018). He was President of the
              Canon Law Society of Tamil Nadu for two terms and Secretary
              General of Canon Law Society of India for a term. He was President
              of the Canon Law Society of India for two terms (2013-2017).
            </Value>
          </Detail>
        </Section>
        <Section>
          <SectionTitle>
            <IconWrapper>
              <FiBookOpen />
            </IconWrapper>
            Education
          </SectionTitle>
          <Detail>
            <Label>School</Label>
            <Value>
              – St. Agnes Minor Seminary and St. Joseph’s Hr. Sec. School,
              Cuddalore (1974-1978)
            </Value>
          </Detail>
          <Detail>
            <Label>Philosophy</Label>
            <Value>
              - Philosophy at Christ Hall Seminary, Madurai (1978-1981)
            </Value>
          </Detail>
          <Detail>
            <Label>Theology</Label>
            <Value>
              - Theology at St. Peter’s Pontifical Seminary, Bangalore
              (1982-1986)
            </Value>
          </Detail>
          <Detail>
            <Label>Masters in Canon Law</Label>
            <Value>
              - Masters in Canon Law from St. Peter’s Pontifical Institute,
              Bangalore (1990-1992)
            </Value>
          </Detail>
          <Detail>
            <Label>Doctorate in Canon Law </Label>
            <Value>
              - Doctorate in Canon Law from Pontificia Università Urbaniana,
              Rome (1992-1994)
            </Value>
          </Detail>
        </Section>
      </SectionContainer>
      <SectionContainer>
        <Section>
          <SectionTitle>
            <IconWrapper>
              <FiMapPin />
            </IconWrapper>
            Ministry
          </SectionTitle>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Title</TableHeader>
                <TableHeader>Period</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <TableData>Vice-Rector, St. Agnes Minor Seminary</TableData>
                <TableData>Cuddalore (1986-1988)</TableData>
              </TableRow>
              <TableRow>
                <TableData> Asst. Parish Priest</TableData>
                <TableData>Viriyur and Karaikal (1988-1989)</TableData>
              </TableRow>
              <TableRow>
                <TableData>Parish Priest </TableData>
                <TableData>Kurumbagaram (1989-1990)</TableData>
              </TableRow>
              <TableRow>
                <TableData>
                  Since 1994, teaching Canon Law at St. Peter’s Pontifical
                  Institute
                </TableData>
                <TableData>Bangalore</TableData>
              </TableRow>
              <TableRow>
                <TableData>
                  Director of the Centre for Canon Law Studies at St. Peter’s
                  Pontifical Institute,
                </TableData>
                <TableData>Bangalore (1996-2001)</TableData>
              </TableRow>
              <TableRow>
                <TableData>Vice-Rector</TableData>
                <TableData>
                  St. Peter’s Pontifical Seminary, Bangalore (200-2006)
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>President</TableData>
                <TableData>
                  {" "}
                  St. Peter’s Pontifical Institute, Bangalore (2010-2017)
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>
                  Executive Secretary of CCBI Commission for Canon Law and
                  Legislative Texts{" "}
                </TableData>
                <TableData>(2010-2018)</TableData>
              </TableRow>
              <TableRow>
                <TableData>
                  President of the Canon Law Society of Tamil Nadu and Secretary
                  General of Canon Law Society of India
                </TableData>
                <TableData></TableData>
              </TableRow>
              <TableRow>
                <TableData>
                  President of the Canon Law Society of India
                </TableData>
                <TableData>(2013-2017)</TableData>
              </TableRow>
            </tbody>
          </Table>
        </Section>
      </SectionContainer>
    </ProfileContainer>
  );
};

export default BishopProfile;
