import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <table className="table table-striped">
          <tbody>
            <tr align="center" valign="top">
              <td className="tableheading" colSpan={3}>
                <strong>
                  <span
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "x-large",
                    }}>
                    SALEM DIOCESE AT A GLANCE
                  </span>
                </strong>
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Erected</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                26-05-1930
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" width="28%">
                <strong>Rite</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Latin Rite
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Ecclesiastical Province</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Pondicherry - Cuddalore
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Location</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Tamilnadu, India
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Postal Address</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Bishop's House P.O.Box 703, 2, Court Road, Maravaneri, Salem -
                636 007 Tamilnadu, INDIA
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Telephone</strong>
              </td>
              <td className="recordtext" nowrap="nowrap" width="27%">
                Bishop’s House (Office) Fax Procurator
              </td>
              <td className="recordtext" nowrap="nowrap" width="45%">
                0427 2415641 0427 2412937 0427 2420148
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Email</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                dioceseofsalem@gmail.com
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Bishop</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Rt. Rev. Arulselvam Rayappan
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Patrons</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                <strong>Primary Patron&nbsp;</strong>: Infant Jesus,
                <strong>Secondary Patron &nbsp;</strong>: Our Lady of Victories
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Area</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                9624 Sq. kms
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>General Population</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                6113525
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>Catholic Population</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                86249
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>No. of Parishes</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                61
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext">
                <strong>No. of Mission Stations</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                259
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Diocesan Priests</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                91
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Religious Priests</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                54
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Religious Brothers</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                66
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Sisters</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                555
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of trained Catechists</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                12
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Untrained Catechists</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                62
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Major Seminarians</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                31
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>No. of Minor Seminarians</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                03
              </td>
            </tr>
            <tr valign="top">
              <td className="recordtext" height={14}>
                <strong>Languages Spoken</strong>
              </td>
              <td className="recordtext" colSpan={2}>
                Tamil, Telugu, Kannada
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AboutUs;
