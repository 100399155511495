import React, { useState, useEffect } from "react";
import { Table, Form, FormControl } from "react-bootstrap";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import { Link, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const DatestoRemember = () => {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [categoryName, setCategoryName] = useState("Diocesan Priests");
    const [loading, setLoading] = useState(true);
    const itemsPerPageOptions = [20, 50, 100];
    const [error, setError] = useState(null);

    const searchParams = new URLSearchParams(location?.search);
    const CategoryFromUrlId = searchParams.get("from");

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${ApiUrl}/get/salemdiocese/preist/${parseInt(CategoryFromUrlId)}`
                );
                setData(response?.data?.data || []);
                if (response?.data?.data?.length > 0) {
                    if (CategoryFromUrlId !== "priest") {
                        setCategoryName(response.data.data[0].category_name);
                    } else {
                        setCategoryName("PRIESTS");
                    }
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [CategoryFromUrlId]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const filteredData = data.filter((item) => {
        return Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchTerm)
        );
    });


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""
            }${month}-${year}`;

        return formattedDate;
    };

    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalItems = filteredData.length;


    return (
        <div className="container">
            {!loading && (
                <>
                    {data.length === 0 ? (
                        <div className="center-message">
                            <p>No Data Available</p>
                        </div>
                    ) : (
                        <div className="table-container">
                            <h3 className="heading">{categoryName}</h3>
                            <Form className="mb-3">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 mb-2">
                                        <FormControl
                                            type="text"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                    <div className="col-md-1 col-sm-12">
                                        <Form.Group controlId="itemsPerPageDropdown">
                                            <Form.Label className="mr-2">Show</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={itemsPerPage}
                                                onChange={handleItemsPerPageChange}>
                                                {itemsPerPageOptions.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Image</th>
                                        <th>Priest Name</th>
                                        <th>Ordination Date</th>
                                        <th>Birthday Date</th>
                                        <th>Mobile Number</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td style={{ width: "80px", height: "87px" }}>
                                                <span>
                                                    <img
                                                        src={`${item.media_url}`}
                                                        alt="Diocesan Priest Icon"
                                                        style={{ width: "80px", height: "87px" }}
                                                    />
                                                </span>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{formatDate(item.date_of_ordination)}</td>
                                            <td>{formatDate(item.date_of_birth)}</td>
                                            <td>{item.phone}</td>
                                            <td>
                                                <Link
                                                    to={`/priestmoredetails?di=${item.id}&from=${CategoryFromUrlId}`}
                                                    style={{ color: "black" }}>
                                                    More Details
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {/* Pagination */}
                            {totalItems > itemsPerPage && (
                                <div>
                                    <ul className="pagination">
                                        {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }).map((_, index) => (
                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                <button onClick={() => paginate(index + 1)} className="page-link" style={{ cursor: "pointer" }}>
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}

            {loading && (
                <CircularProgress
                    sx={{ display: "block", margin: "auto", marginTop: "20em" }}
                />
            )}
            {error && (
                <div className="center-message">
                    <p>Error fetching data: {error.message}</p>
                </div>
            )}
        </div>
    );
};

export default DatestoRemember;
