import React, { useEffect, useState } from "react";
import NoImage from "../assets/img/noimage.png";
import { Link } from "react-router-dom";

function Upcoming({ projectdata }) {
  const [eventsData, setEventsData] = useState([]);
  const [upcomeData, setUpcomeData] = useState([]);
  const [birthdayData, setBirthdayData] = useState([]);

  const isEventOnCurrentDate = (eventDate) => {
    const currentDate = new Date();
    return eventDate.toDateString() === currentDate.toDateString();
  };

  useEffect(() => {
    // Fetch data for Latest News
    if (Array.isArray(projectdata) && projectdata.length > 0) {
      const currentDate = new Date();
      const data = projectdata
        ?.filter((event) => event.category_id === 1)
        .map((event) => ({
          ...event,
          eventDate: event.eventdate
            ? new Date(event.eventdate.split("-").reverse().join("-"))
            : null,
          endDate: event.end_date
            ? new Date(event.end_date.split("-").reverse().join("-"))
            : null,
        }));

      const filteredData = data.filter(
        (event) =>
          !event.endDate ||
          event.endDate >= currentDate ||
          event.endDate.toDateString() === currentDate.toDateString()
      );

      const sortedData = filteredData.sort((a, b) => a.eventDate - b.eventDate);

      setEventsData(sortedData.slice(0, 3));
    } else {
      setEventsData([]);
    }

    // Fetch data for Upcoming Events
    if (Array.isArray(projectdata) && projectdata.length > 0) {
      const currentDate = new Date();
      const data = projectdata.map((event) => ({
        ...event,
        eventDate: event.eventdate
          ? new Date(event.eventdate.split("-").reverse().join("-"))
          : null,
        endDate: event.end_date
          ? new Date(event.end_date.split("-").reverse().join("-"))
          : null,
      }));

      const upcomeData = data.filter((event) => event.category_id === 2);

      const filteredData = upcomeData.filter(
        (event) =>
          !event.endDate ||
          event.endDate >= currentDate ||
          event.endDate.toDateString() === currentDate.toDateString()
      );

      const sortedData = filteredData.sort((a, b) => a.eventDate - b.eventDate);

      setUpcomeData(sortedData.slice(0, 3));
    } else {
      setUpcomeData([]);
    }
  }, [projectdata]);

  const isBirthdayOnCurrentDate = (birthdayDate) => {
    const currentDate = new Date();
    const birthday = new Date(birthdayDate);
    return (
      birthday.getMonth() === currentDate.getMonth() &&
      birthday.getDate() === currentDate.getDate()
    );
  };

  useEffect(() => {
    // Fetch data for Birthdays
    if (Array.isArray(projectdata) && projectdata.length > 0) {
      const currentDate = new Date();
      const data = projectdata
        ?.filter((event) => event.category_id === 3)
        .map((event) => ({
          ...event,
          eventDate: event.eventdate
            ? new Date(event.eventdate.split("-").reverse().join("-"))
            : null,
        }));

      const filteredData = data.filter(
        (event) => event.eventDate && event.eventDate >= currentDate // Filter out past events
      );

      const sortedData = filteredData.sort((a, b) => a.eventDate - b.eventDate);

      setBirthdayData(sortedData.slice(0, 3));
    } else {
      setBirthdayData([]);
    }
  }, [projectdata]);

  const istEventOnCurrentDate = (eventDate) => {
    const currentDate = new Date();
    return eventDate.toDateString() === currentDate.toDateString();
  };

  return (
    <>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="section-heading">
                <h2 className="entry-title">News & Events</h2>
              </div>
              <div className="scrollable-content" id="provinceprog">
                {eventsData?.map((latestData, index) => (
                  <article className="rcardnew shadow curve" key={index}>
                    <div className="mb-3">
                      <img
                        src={latestData?.media_url || NoImage}
                        alt="noimage"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <p>
                        <Link
                          to={"/latestevents"}
                          style={{ textDecoration: "none" }}
                        >
                          <strong style={{ color: "black" }}>
                            {latestData?.title}
                          </strong>
                        </Link>
                      </p>
                      <span style={{ fontSize: "14px" }}>
                        <i className="fa fa-clock" />
                        &nbsp;<time>{latestData?.eventdate}</time>
                      </span>
                    </div>
                    {isEventOnCurrentDate(latestData?.eventDate) && (
                      <img
                        src="/images/all-img/new.gif"
                        alt="gif"
                        className="gif-image"
                        style={{
                          height: 60,
                          width: 60,
                          marginLeft: 100,
                          marginBottom: 34,
                        }}
                      />
                    )}
                  </article>
                ))}
                <br />
                {eventsData.length === 0 && (
                  <p style={{ color: "black", fontWeight: "bold" }}>
                    No Latest News Available
                  </p>
                )}
                {eventsData.length >= 3 && (
                  <div style={{ textAlign: "center" }}>
                    <Link
                      to="/latestevents"
                      style={{
                        backgroundColor: "#012c6d",
                        color: "white",
                        textDecoration: "none",
                        display: "inline-block",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        marginTop: "10px",
                        fontSize: "14px",
                      }}
                    >
                      View More
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="section-heading">
                <h2 className="entry-title">Calendar Events</h2>
              </div>
              <div className="scrollable-content" id="provincialprog">
                {upcomeData?.map((upcome, index) => (
                  <article className="rcardnew shadow curve" key={index}>
                    <div className="mb-3">
                      <img
                        src={upcome?.media_url || NoImage}
                        alt="noimage"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <p>
                        <Link
                          to={"/upcomeevents"}
                          style={{ textDecoration: "none" }}
                        >
                          <strong style={{ color: "black" }}>
                            {upcome?.title}
                          </strong>
                        </Link>
                      </p>
                      <span style={{ fontSize: "14px" }}>
                        <i className="fa fa-clock" />
                        &nbsp;<time>{upcome.eventdate}</time>
                      </span>
                    </div>
                    {istEventOnCurrentDate(upcome.eventDate) && (
                      <img
                        src="/images/all-img/new.gif"
                        alt="gif"
                        className="gif-image"
                        style={{
                          height: 60,
                          width: 60,
                          marginLeft: 100,
                          marginBottom: 34,
                        }}
                      />
                    )}
                  </article>
                ))}
                <br />
                {upcomeData?.length === 0 && (
                  <p style={{ color: "black", fontWeight: "bold" }}>
                    No Calendar Events Available
                  </p>
                )}
                {upcomeData.length >= 3 && (
                  <div style={{ textAlign: "center" }}>
                    <Link
                      to="/upcomeevents"
                      style={{
                        backgroundColor: "#012c6d",
                        color: "white",
                        textDecoration: "none",
                        display: "inline-block",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        marginTop: "10px",
                        fontSize: "14px",
                      }}
                    >
                      View More
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="section-heading">
                <h2 className="entry-title">Wishes</h2>
              </div>
              <div className="scrollable-content" id="provincialprog">
                {birthdayData?.map((birthday, index) => (
                  <article className="rcardnew shadow curve" key={index}>
                    <div className="mb-3">
                      <img
                        src={birthday?.media_url || NoImage}
                        alt="noimage"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <p>
                        <strong style={{ color: "black" }}>
                          {birthday?.title}
                        </strong>
                      </p>
                      <span style={{ fontSize: "14px" }}>
                        <i className="fa fa-clock" />
                        &nbsp;<time>{birthday.eventdate}</time>
                      </span>
                    </div>
                    {isBirthdayOnCurrentDate(birthday.eventDate) && (
                      <img
                        src="/images/all-img/new.gif"
                        alt="gif"
                        className="gif-image"
                        style={{
                          height: 60,
                          width: 60,
                          marginLeft: 100,
                          marginBottom: 34,
                        }}
                      />
                    )}
                  </article>
                ))}
                <br />
                {birthdayData?.length === 0 && (
                  <p style={{ color: "black", fontWeight: "bold" }}>
                    No Wishes Available
                  </p>
                )}
                {birthdayData.length >= 3 && (
                  <div style={{ textAlign: "center" }}>
                    <Link
                      to="/upcomeevents"
                      style={{
                        backgroundColor: "#012c6d",
                        color: "white",
                        textDecoration: "none",
                        display: "inline-block",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        marginTop: "10px",
                        fontSize: "14px",
                      }}
                    >
                      View More
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Upcoming;
