import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layout";
import Home from "./home";
import History from "./pages/AboutUs/history";
import DiocesanPriests from "./pages/Priests/DiocesanPriests";
import Contact from "./pages/Contact/Contact";
import UpcomingEvents from "./pages/Events/UpcomingEvents";
import LatestEvents from "./pages/Events/LatestEvents";
import Donate from "./pages/Donation/Donate";
import AllPriestDetails from "./pages/Priests/PriestAllDetails/AllPriestDetails";
import BishopProfile from "./pages/administration/BishopProfile";
import AboutUs from "./pages/AboutUs/aboutUs";
import Administration from "./pages/administration/administration";
import Vicariates from "./pages/Parishes/Vicariates";
import ParishesNew from "./pages/Parishes/parishesnew";
import NewParishDetails from "./pages/Parishes/AllParishDetails/NewPairshesDetails";
import Instituation from "./pages/Institution/instituation";
import DiocesanShrine from "./pages/Parishes/DiocesanShrine";
import SynodofBishops from "./pages/SynodofBishops/SynodofBishops";
import BishopAudio from "./pages/audireillum/BishopAudio";
import Photos from "./pages/audireillum/photos";
import ReligiousData from "./pages/Priests/ReligiousData";
import DatestoRemebers from "./pages/Priests/DatestoRemebers";
import BishopMessage from "./pages/Bishop/BishopMessage";
import NewsLetter from "./pages/Bishop/NewsLetter";
import HolyFatherMessage from "./pages/Bishop/HolyFatherMessage";
import Monthlyprogramme from "./pages/Bishop/MonthlyProgram";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          {/* About us Section Page Routing */}

          <Route path="aboutus" element={<AboutUs />} />
          <Route path="history" element={<History />} />
          <Route path="bishop-emeritus" element={<History />} />
          <Route path="cathedral" element={<History />} />
          <Route path="bishopprofile" element={<BishopProfile />} />
          <Route path="patron" element={<History />} />

          {/* Administration Section Page Routing */}

          <Route path="college-of-consulters" element={<Administration />} />
          <Route path="senate" element={<Administration />} />
          <Route path="epiphany-centre" element={<Administration />} />
          <Route
            path="commissions-associations-and-secretaries"
            element={<Administration />}
          />
          <Route
            path="salem-social-service-society"
            element={<Administration />}
          />
          <Route path="salem" element={<Administration />} />
          <Route path="namakkal" element={<Administration />} />

          {/* Parish Section Routing Page */}

          <Route path="vicariates" element={<Vicariates />} />
          <Route path="subparishes" element={<ParishesNew />} />
          <Route path="diocesan-shrine" element={<DiocesanShrine />} />
          <Route path="masstimings" element={<DiocesanShrine />} />
          <Route path="parishmoredetails" element={<NewParishDetails />} />

          {/* Priest Section Routing Page */}

          <Route path="diocesan-priest" element={<DiocesanPriests />} />
          <Route path="priestmoredetails" element={<AllPriestDetails />} />
          <Route path="religious-priests" element={<ReligiousData />} />
          <Route path="religious-brothers" element={<ReligiousData />} />
          <Route path="sisters" element={<ReligiousData />} />
          <Route path="necrology" element={<ReligiousData />} />
          <Route path="dates-remember" element={<DatestoRemebers />} />

          {/* Institution Section Routing Page */}

          <Route path="minor-seminary" element={<Instituation />} />
          <Route path="diocesean-school" element={<Instituation />} />
          <Route path="religious-school" element={<Instituation />} />
          <Route path="hostel-for-boys" element={<Instituation />} />
          <Route path="hostel-for-girls" element={<Instituation />} />
          <Route path="orphanages-boys" element={<Instituation />} />
          <Route path="orphanages-girls" element={<Instituation />} />
          <Route
            path="orphanages-for-boys-and-girls"
            element={<Instituation />}
          />
          <Route path="other-social-services" element={<Instituation />} />
          <Route path="old-age-homes" element={<Instituation />} />

          {/* Synod of Bishop Section Page Routing */}

          <Route path="preparation-phase" element={<SynodofBishops />} />
          <Route path="training-phase" element={<SynodofBishops />} />
          <Route path="pre-synod" element={<SynodofBishops />} />
          <Route path="consultation-phase" element={<SynodofBishops />} />
          <Route path="consolidation-phase" element={<SynodofBishops />} />
          <Route path="jubilee" element={<SynodofBishops />} />

          {/* Audrie Illum Section Page Routing */}

          <Route
            path="bishop-rayappans-audio-and-video-talks"
            element={<BishopAudio />}
          />
          <Route path="photos" element={<Photos />} />

          {/* Donate Section Page Routing */}

          <Route path="seminarians-fund" element={<Donate />} />
          <Route path="educationfund" element={<Donate />} />
          <Route path="Development-fund" element={<Donate />} />

          {/* Contact Us Section Page Routing */}

          <Route path="contactus" element={<Contact />} />

          {/* Events Page Routing */}
          <Route path="upcomeevents" element={<UpcomingEvents />} />
          <Route path="latestevents" element={<LatestEvents />} />

          {/* Bishop Section Page Routing */}
          <Route path="bishopmessage" element={<BishopMessage />} />
          <Route path="newsletter" element={<NewsLetter />} />
          <Route path="holyfathermessage" element={<HolyFatherMessage />} />
          <Route path="monthlyprogram" element={<Monthlyprogramme />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
