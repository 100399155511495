import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="home-page-welcome">
        <div className="col-lg-7">
          <div className="container">
            <h2 className="text-white">
              <b>WELCOME TO THE DIOCESE OF SALEM</b>
            </h2>
            <p
              className="text-white"
              style={{ textAlign: "justify", lineHeight: "2rem" }}>
              The Diocese of Salem comprises of civil districts of Salem and
              Namakkal. In 1623 Southern Kongunadu, part of Salem District,
              under Madurai Nayakkars accepted Christianity, through Fr. Robert
              De Nobili, S.J. and his successors, the Portuguese Jesuits of the
              Madurai Mission. About 1654, the Italian Jesuits of the Mysore
              mission had a residence near Hosur. In 1687, the Mysore Mission
              laboured in the entire area of the present diocese. Fr. De Cunha
              died in 1711 at Kapiganathi near Hosur.
            </p>
            <Link to="/history">
              <button className="brown-button">Read More</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
