import React, { useState, useEffect } from "react";
import { Table, Form, FormControl } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import CircularProgress from "@mui/material/CircularProgress";

const Vicariates = () => {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortColumn, setSortColumn] = useState("vicariate_name");
    const [sortDirection, setSortDirection] = useState("asc");
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [20, 50, 100];
    const [categoryName, setCategoryName] = useState("Vicariate Details");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const searchParams = new URLSearchParams(location?.search);
    const CategoryFromUrlId = searchParams.get("from");

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            axios
                .get(`${ApiUrl}/get/salemdiocese/parish/${parseInt(CategoryFromUrlId)}`)
                .then((response) => {
                    setLoading(false);
                    setData(response?.data?.data || []);
                    if (response?.data?.data?.length > 0) {
                        if (CategoryFromUrlId !== "parish") {
                            setCategoryName(response.data.data[0].category_name);
                        } else {
                            setCategoryName("PARISHES");
                        }
                    }
                    setCategoryName("Vicariates List");
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error);
                });
        };

        fetchData();
    }, [CategoryFromUrlId]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        setSortColumn(column);
        setSortDirection((prevSortDirection) =>
            column === sortColumn && prevSortDirection === "asc" ? "desc" : "asc"
        );
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const groupedData = data.reduce((acc, item) => {
        acc[item.vicariate_name] = [...(acc[item.vicariate_name] || []), item];
        return acc;
    }, {});

    const vicariateNames = Object.keys(groupedData);

    const sortedVicariateNames = vicariateNames.sort((a, b) => {
        const columnA = a.toLowerCase();
        const columnB = b.toLowerCase();

        if (columnA < columnB) {
            return sortDirection === "asc" ? -1 : 1;
        }
        if (columnA > columnB) {
            return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    });

    const currentVicariateNames = sortedVicariateNames.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const totalItems = vicariateNames.length;

    return (
        <>
            <div className="container">
                {!loading && !error && (
                    <>
                        {vicariateNames.length === 0 ? (
                            <div className="center-message">
                                <p>No Data Available.</p>
                            </div>
                        ) : (
                            <div className="table-container">
                                <h3 className="heading">{categoryName}</h3>
                                <Form className="mb-3">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 mb-2">
                                            <FormControl
                                                type="text"
                                                placeholder="Search"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                        <div className="col-md-1 col-sm-12">
                                            <Form.Group controlId="itemsPerPageDropdown">
                                                <Form.Label className="mr-2">Show</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={itemsPerPage}
                                                    onChange={handleItemsPerPageChange}
                                                >
                                                    {itemsPerPageOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <br />
                                    <b>Total Vicariates: {totalItems}</b>
                                </Form>

                                {currentVicariateNames.map((vicariateName) => (
                                    <React.Fragment key={vicariateName}>
                                        <h4>{vicariateName}</h4>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th onClick={() => handleSort("parish_name")}>
                                                        Parish Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {groupedData[vicariateName].map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.parish_name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </React.Fragment>
                                ))}

                                {/* Pagination */}
                                {totalItems > itemsPerPage && (
                                    <div>
                                        <ul className="pagination">
                                            {Array.from({
                                                length: Math.ceil(totalItems / itemsPerPage),
                                            }).map((_, index) => (
                                                <li
                                                    key={index}
                                                    className={`page-item ${currentPage === index + 1 ? "active" : ""
                                                        }`}
                                                >
                                                    <button
                                                        onClick={() => paginate(index + 1)}
                                                        className="page-link"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {loading && (
                    <CircularProgress
                        sx={{ display: "block", margin: "auto", marginTop: "20em" }}
                    />
                )}

                {error && (
                    <div className="center-message">
                        <p>Error fetching data: {error.message}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default Vicariates;
